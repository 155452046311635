.socialinitiavties {
  /* background-image: url("../../../../public/assets/AboutPage/socialinitiativies/backimg.png");
  background-repeat: no-repeat;
  background-size: cover; */
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 150px 0px 130px 0px;
}

.images-container {
  display: grid;
  grid-template-columns: 3fr 30%; /* Image column and sidebar column */
  position: relative;

  margin: 20px 0px 20px 0px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(150, 25, 90, 0.6);
}

.img-container {
  width: 100%;
  height: 100%;
}

.sidebar {
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  height: 100%;
  background-color: var(--theme-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

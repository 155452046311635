.progress-section {
  width: 100%;
  border-radius: 15px;
  margin: 0 auto;
}

.task-progress {
  margin: 0.5rem 0;
}

.task-progress p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--light-black);
}

/* span {
  color: #eee;
} */

.progress {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 10px;
  height: 4px;
}

.progress::-webkit-progress-bar {
  background-color: #e4e4e4 !important;
}

.progress::-webkit-progress-bar,
.progress::-webkit-progress-value {
  border-radius: 10px;
}

.progress::-moz-progress-bar {
  border-radius: 10px;
}

.progress1::-webkit-progress-value {
  background: var(--theme-color) !important;
}

.bottomstrongerSection {
  background-image: url("../../../../public/assets/AboutPage/strongerimages/Bottombackimg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  margin-bottom: 80px;
}

/* //remove background image for mobile */
@media (max-width: 400px) {
  .bottomstrongerSection {
    background-image: none;
  }
}

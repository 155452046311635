/* CSS */
.image-cont {
  position: relative; /* Required for absolute positioning of lines */
  display: inline-block; /* or 'block' if needed */
  /* Add any other styles you need for the container */
}

.line-vert,
.line-horiz {
  position: absolute;
  background-color: #000; /* Set the color of the lines as per your requirement */
}

.line-vert {
  position: absolute;
  top: 0;
  right: 0px;
  width: 20px;
  height: 50%;
  background-color: var(
    --theme-color
  ); /* Set your desired color for the vertical line */
}

.line-horiz {
  position: absolute;
  bottom: 0px;
  /* top: 290px; */
  left: -50px;
  width: 45%;
  z-index: 1;
  height: 22px;
  background-color: var(
    --theme-color
  ); /* Set your desired color for the horizontal line */
}

.support_activity {
  background-image: url("../../../../public/assets/Support/supportbackgroundimg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;

}

.supportdesc {
  text-align: justify;
  width: 50%;
  margin-left: 50px;
  margin-right: 50px;
  font-size: 20px;
}

/* //make a circle background for the icon */
.videocam {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  background-color: var(--theme-color);
  color: var(--white);
  z-index: 3;
  left: 20px;
  position: relative;
}

/* Define the animation for the blur circles */
@keyframes blurCircle {
  0% {
    transform: scale(1);
    filter: blur(0);
  }
  50% {
    transform: scale(1.5);
    filter: blur(2px);
  }
  100% {
    transform: scale(1);
    filter: blur(0);
  }
}

/* Apply the animation to the video play icon and its surrounding circles */
.video-icon {
  animation: blurCircle 2s linear infinite;
}

.blur-circle {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f1e0e9;
  animation: blurCircle 4s linear infinite;
  opacity: 0.3;
  pointer-events: none;
  /* left: 790px;
  margin-top: -15px; */
  z-index: 0;
}

/* Adjust the positioning of the video play icon */
.video-icon-container {
  position: relative;
}

/* Form.css */
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust height as needed */
  background-color: black; /* Set background color to black */
}

.form {
  text-align: center;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
}

.form label {
  display: block;
  margin-bottom: 10px;
}

.form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}

.form button {
  padding: 8px 20px;
  background-color: var(--theme-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form button:hover {
  background-color: var(--white);
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
}

.footer {
  background-image: url("../../../../public/assets/footer/footerbackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
  padding-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  margin-top: 80px;
}

.horizontal-line {
  border-top: 1px solid var(--title-backgrd-color);
  margin: 1px 0;
}

.socialmediaicons .socialmediaiconlist .mediaiconstags {
  text-align: center;
  display: inline-block;
  position: relative;
  overflow: hidden;
  border: 3px solid var(--white);
  z-index: 1;
}

.socialmediaicons .socialmediaiconlist .mediaiconstags .icon {
  position: relative;
  color: var(--theme-color);
  border-right: 2px solid #e4e4e4;
  transition: 1s;
  z-index: 3;
}

.socialmediaicons .socialmediaiconlist .mediaiconstags:hover .icon {
  color: #fff;
  /* transform: rotateY(360deg); */
}

.socialmediaicons .socialmediaiconlist .mediaiconstags:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  /* transition: 0.5s; */
  z-index: 2;
}

.socialmediaicons .socialmediaiconlist .mediaiconstags:hover:before {
  top: 0;
}

.socialmediaicons .socialmediaiconlist:nth-child(1) .mediaiconstags:before {
  background: #3b5999;
}

.socialmediaicons .socialmediaiconlist:nth-child(2) .mediaiconstags:before {
  background: black;
}

.socialmediaicons .socialmediaiconlist:nth-child(3) .mediaiconstags:before {
  background: #25d366;
}

.socialmediaicons .socialmediaiconlist:nth-child(4) .mediaiconstags:before {
  background: #cd201f;
}
.socialmediaicons .socialmediaiconlist:nth-child(5) .mediaiconstags:before {
  background: #ff0050;
}

.paymentlogo:hover {
  transform: scale(1.2);
  transition: 0.5s;
  cursor: pointer;
}

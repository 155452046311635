.App {
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: "Arbutus Slab", serif;
  height: 100%;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.testimonial {
  background-image: url("../../../../public/assets/testimonialbackgrd.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 65vh; */
  width: 100%;
  padding: 10px 10px 20px 10px;
  margin-bottom: 30px;
}

.testimonial__card {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-next {
  right: 0;
}
/* .testimonial__card__img {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

/* .testimonial__card__description {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: justify;
} */

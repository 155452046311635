.supportproject {
  /* background-image: url("../../../public/assets/Support/supportproject.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 80px;
  /* padding: 20px 0px 0px 0px; */
}
.supporprojecttdesc {
  text-align: justify;
  /* margin-left: 50px;
  margin-right: 50px; */
  font-size: 20px;
}

.story-cont {
  /* background-image: url(../../../../public/assets/AboutPage/Storyimages/storybackimg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 100px;
  padding: 100px 0px 20px 0px;
  position: relative;
}

.images-cont {
  background-image: url(../../../../public/assets/AboutPage/Storyimages/backgrdLines.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(150, 25, 90, 0.6);
}

/* .center-image-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
} */

.bottom-image {
  width: 100%;
  height: auto;
}

/* //for mobile view */
@media (max-width: 900px) {
  .story-cont {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* .story-cont2 {
    padding: auto;
  } */

  .images-cont {
    background-image: none;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  /* .bottom-image {
    width: 80%;
    height: auto;
    position: absolute;
    margin-top: 100px;
    margin-left: 100px;
  }

  .top-image {
    height: auto;
    position: relative;
    margin-top: 100px;
    margin-left: 100px;
  } */
}

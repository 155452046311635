.sidebox {
  position: absolute;
  top: 0;
  right: 0;
  width: 25%;
  height: 100%;
  background-color: var(--theme-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscribe {
  background-image: url("../../../../public/assets/subscribe/subscribebackgrd.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
  width: 100%;
  margin-bottom: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 20px;
  /* padding: 20px; */
}

.left-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: justify;
  margin-left: 30px;
  width: 70%;
}

.layout {
  background-image: url("../../../../public/assets/AboutPage/quotes.png");
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: right top;
  /* margin-bottom: 80px; */
}
.slider-bottom-container {
  margin-bottom: 150px;
  width: 100%;
  margin-top: 60px;
  display: inline-block;
  text-align: center;
  align-items: center;
}

.slider-item {
  display: flex;
  /* flex-direction: row; */
  /* justify-content: space-between; */
  text-align: center;
  /* position: relative; */
}

/* CSS */
.image-container {
  position: relative; /* Required for absolute positioning of lines */
  display: inline-block; /* or 'block' if needed */
 
  /* Add any other styles you need for the container */
}

.line-vertical,
.line-horizontal {
  position: absolute;
  background-color: #000; /* Set the color of the lines as per your requirement */
}

.line-vertical {
  position: absolute;
  top: 0;
  left: 240px;
  width: 18px;
  height: 23%;
  background-color: var(
    --theme-color
  ); /* Set your desired color for the vertical line */
}

.line-horizontal {
  position: absolute;
  bottom: 0px;
  top: 290px;
  left: 0;
  width: 35%;
  height: 18px;
  background-color: var(
    --theme-color
  ); /* Set your desired color for the horizontal line */
}
.custom-paging {
  width: 500px;
}

p {
  font-family: "var(--primary-font)";
}

.slick-slider img {
  width: 250px;
  height: 300px;
  object-position: center;
  object-fit: cover;
}

.slick-slider .slick-dots.slick-thumb img {
  width: 50px;
  height: 50px;
  object-position: center;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 100px;
}

.slick-dots .slick-active {
  opacity: 1;
}

.slick-dots li {
  width: 60px;

  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}

.slick-dots li:hover {
  opacity: 1;
}

/* //item slider in mobile view flexdirection: column */
@media (max-width: 400px) {
  .slider-item {
    display: flex;
    flex-direction: column;
  }
}

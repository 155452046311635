.navbar {
  background-color: #fff;
}
.nav-NavLink {
  text-decoration: none;
  color: #676767;
  font-size: 15px;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.nav-NavLink.active {
  text-decoration: underline;
  color: var(--theme-color);
}
.navbar-nav {
  font-size: 17px;
  font-weight: 500;
  margin-left: 5rem;
  display: flex;
  justify-content: space-evenly;
}

.nav-item {
  padding-left: 25px;
}
.nav-item .nav-NavLink:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 27px;
  height: 15px;
  bottom: 15px;
  border: 1px solid var(--light-black);
  margin-left: 20px;
}

/* //remove :after in mobile and tablet view */
@media (max-width: 1000px) {
  .navbardiv {
    display: none;
    background-color: white;
  }
  .nav-item .nav-NavLink:after {
    display: none;
  }
  .navbar-nav {
    margin-left: 0;
  }
}

@media (max-width: 1200px) {
  .nav-NavLink {
    font-size: 13px;
    padding: 0.5rem 0.6rem;
  }
}

.socialmedia-icon:hover {
  transform: rotateY(360deg);
  transition: 1.8s;
  color: var(--white);
}

.facebook-icon:hover {
  background-color: #3b5999;
}

.twitter-icon:hover {
  background-color: #55acee;
}

.whatsapp-icon:hover {
  background-color: #25d366;
}

.youtube-icon:hover {
  background-color: #cd201f;
}

.tiktok-icon:hover {
  background-color: black;
}

.email-icon:hover {
  color: #55acee;
}

.person-icon:hover {
  color: #55acee;
}

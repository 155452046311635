body {
  margin: 0;
  font-family: "Arbutus Slab", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Arbutus Slab", serif;
}

:root {
  --white: #fefcfc;
  --black: #1a1a1a;
  --theme-color: #96195a;
  --title-backgrd-color: #f1e0e9;
  --light-theme-color: #e0bace;
  --light-black: #535353;
  --yellow: #f6c000;
  --primary-font: "Arbutus Slab", serif;
  --light-grey: #e4e4e 4;
  --btn-color: #808080;
  --back-color: #f8f8f8;
  --stock-green: #1b9619;
  --very-lightthemecolor: #f9f3f6;
  --green: #49ab47;
  --pathbar-color: #f4f4f4;

  --invoice--color: #5d6f79;
  --invoice--color--light: #909da3;
  --invoice--color--lighter: #f1f5f8;
}

.blogall {
  /* display: flex;
  flex-direction: row; */
  flex-wrap: wrap;
  /* justify-content: flex-start; */
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.blogrow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
}

.image {
  width: 45%;
}

.description {
  width: 45%;
  text-align: justify;
  color: var(--light-black);
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .image,
  .description {
    width: 100%;
  }
}

.bar-item:not(:first-child) {
  padding-left: 30px;
}
.bar-item .bar-NavLink:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 130px;
  height: 15px;
  bottom: 15px;
  border: 1px solid var(--white);
  margin-left: 20px;
}

.accounttop-section {
  background-color: var(--theme-color);

  text-align: center;
  margin-top: 0.4px;
  color: white;
  font-family: var(--primary-font);
}

.account-section {
  background-color: var(--theme-color);
  text-align: center;
  margin-top: 0.4px;
  color: white;
  font-family: var(--primary-font);
}
